<template>
  <div :key="JSON.stringify(this.$i18n.locale)">
    <h3 margin-top="20px">{{ $t('Global Charts') }}</h3>
    <draggable class="draggable-list row" group="my-group">
      <b-col cols="12" lg="6">
        <Widget :widgetData="finalQuantityChart" />
      </b-col>
      <b-col cols="12" lg="6">
        <Widget :widgetData="allWorkcentersPPMChart" />
      </b-col>
      <b-col cols="12" lg="6">
        <Widget :widgetData="breakdownRateChart" />
      </b-col>
      <b-col cols="12" lg="6">
        <Widget :widgetData="finalKPIChart" />
      </b-col>
    </draggable>
  </div>
</template>
<script>
//send params to 'Chart' component
import Widget from "../widgets/Widget.vue";
import draggable from "vuedraggable";
import useGlobalChartOptions from "../composables/SectionHomeChart";
import DateFilter from "../DateFilter.vue";
import { ref } from "@vue/composition-api";
import store from "@/store";
import {
  BButton,
  BCardBody,
  BCardFooter,
  BCardHeader,
  BCard,
  BCardTitle,
  BRow,
  BCol,
} from "bootstrap-vue";

export default {
  name: "GlobalCharts",
  components: {
    Widget,
    DateFilter,
    BButton,
    BCardBody,
    BCardFooter,
    BCardHeader,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    draggable,
  },
  setup() {
    store.dispatch("pamis-home/getGlobalChartData");
    const {
      finalQuantityChart,
      allWorkcentersPPMChart,
      finalKPIChart,
      breakdownRateChart,
    } = useGlobalChartOptions();
    const key = ref(0);
    function changeData() {
      key.value++;
      allWorkcentersPPMChart.value.series[0].data[0] = Math.random() * 50000;
    }

    return {
      finalQuantityChart,
      allWorkcentersPPMChart,
      finalKPIChart,
      breakdownRateChart,
      key,
      changeData,
    };
  },
};
</script>
<style scoped>
.outer-card {
  background-color: rgba(0, 0, 0, 0);
}
.inner-card {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px !important;
}
</style>
