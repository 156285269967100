<template>
  <div>
    <l-map style="z-index: 0" :zoom="zoom" :center="center" :minZoom="3.0">
      <l-tile-layer :url="url" :continuousWorld="false" :noWrap="true" />
      <div v-for="country in data.countryList" :key="country.name">
        <div v-if="country.name == 'Turkey'">
          <l-marker @click="navigateTo(country)" :lat-lng="markerLatLng" />
          <l-circle
            :lat-lng="circle.center"
            :radius="circle.radius"
            :color="circle.color"
            @click="navigateTo(country)"
          />
        </div>
      </div>
    </l-map>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  LMap,
  LTileLayer,
  LMarker,
  LCircle,
  LPolygon,
  LGeoJson,
} from "vue2-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
/* eslint-disable global-require */

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LCircle,
    LPolygon,
    LGeoJson,
  },
  props: {
    data: {
      widgetType: String,
      countryList: Array,
    },
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      noWrap: true,
      zoom: 3.0,
      center: [38.3325354, 27.1198419],
      markerLatLng: [38.3325354, 27.1198419],
      circle: {
        center: [38.3325354, 27.1198419],
        radius: 200000,
        color: "green",
      },
      polygon: {
        latlngs: [
          [2, 2],
          [2, 4],
          [6, 5],
          [6, 5],
        ],
        color: "#ff0000",
      },
    };
  },
  methods: {
    navigateTo(country) {
      this.$router.push(`/country/${country.name}/id=${country.id}/tab=0`);
    },
  },
};
</script>

<style lang="scss">
.vue2leaflet-map {
  &.leaflet-container {
    height: 600px;
  }
}
</style>
