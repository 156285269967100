<template>
  <div>
    <div class="container-fluid">
      <b-card class="main-card">
        <global-dataviz></global-dataviz>
      </b-card>
    </div>
  </div>
</template>
<script>
import {
  BCol,
  BRow,
  BCard,
  BCardTitle,
  BCardBody,
  BCardFooter,
} from "bootstrap-vue";
import GlobalDataviz from "./SectionHome.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import homeStoreModule from "../homeStoreModule";
import homeStoreModule_2 from "../store/homeStoreModule";
import store from "@/store";
export default {
  components: {
    GlobalDataviz,
    BCol,
    BRow,
    BCard,
    BCardTitle,
    BCardBody,
    BCardFooter,
  },
  methods: {
    logout() {
      //TODO: Not implemented yet
      this.$store.dispatch("auth/signOut");
    },
  },
  setup() {
    const PAMIS_MODULE_NAME = "pamis-home";
    if (!store.hasModule(PAMIS_MODULE_NAME))
      store.registerModule(PAMIS_MODULE_NAME, homeStoreModule);

    const PAMIS_HOME_MODULE = "pamis-home-module";
    if (!store.hasModule(PAMIS_HOME_MODULE))
      store.registerModule(PAMIS_HOME_MODULE, homeStoreModule_2);
    store.dispatch("pamis/GetCountries");

    onUnmounted(() => {
      if (store.hasModule(PAMIS_MODULE_NAME))
        store.unregisterModule(PAMIS_MODULE_NAME);
      if (store.hasModule(PAMIS_HOME_MODULE))
        store.unregisterModule(PAMIS_HOME_MODULE);
    });
  },
};
</script>
<style scoped>
.main-card {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 6px 0px;
}
</style>