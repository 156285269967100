<template>
  <div id="controlchart">
    <b-card no-body>
      <b-card-body style="padding: 12px">
        <highcharts
          :constructor-type="'chart'"
          style="padding-left: 0px"
          :options="options"
      /></b-card-body>
    </b-card>
  </div>
</template>
<script>
import { BCardBody, BCard, BRow, BCol, BButton } from "bootstrap-vue";
import { Chart } from "highcharts-vue";
export default {
  props: {
    data: {
      type: Object,
    },
  },
  components: {
    Highcharts: Chart,
    BCard,
    BRow,
    BCol,
    BButton,
    BCardBody,
  },
  data() {
    return {
      options: {
        credits: {
          enabled: false,
        },
        scrollbar: { enabled: false },
        rangeSelector: {
          enabled: false,
          selected: 4,
          inputEnabled: false,
          buttonTheme: {
            visibility: "hidden",
          },
          allButtonsEnabled: false,
          labelStyle: {
            visibility: "hidden",
          },
        },
        navigator: {
          visible: false,
          enabled: false,
        },
        chart: {
          type: "column",
        },
        yAxis: [
          {
            title: "",
            min: 0,
          },
        ],
        legend: {
          enabled: true,
          shadow: false,
        },
        tooltip: {
          shared: true,
        },
        title: {
          text: "Efficiency Optimization by Branch",
        },

        xAxis: {
          type: "category",
          categories: ["Brazil", "Turkey", "USA", "China"],
        },
        series: [
          {
            name: "Total Quantity",
            color: "rgba(165,170,255,1)",
            data: [150, 73, 20, 120],
          },
          {
            name: "NOKQuantity",
            color: "rgba(90,86,134,1)",
            data: [140, 90, 40, 10],
          },
        ],
        ...this.data,
      },
    };
  },
  methods: {
    increaseValue() {
      this.options.series[0].data[3] += 10;
      let updatedOptions = JSON.parse(JSON.stringify(this.options));
      this.options.series = updatedOptions.series;
    },
  },
};
</script>
