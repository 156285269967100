import { computed } from "@vue/composition-api";
import store from '@/store'
import chartColorPalettes from "@/assets/widget/highchartGradientColorPalettes.json";
export default function useGlobalChartOptions() {
  let finalQuantityChart = computed(function () {
    let optionsData = store.getters["pamis-home/getGlobalChartData"];
    let options = {
      widgetType: "HighchartColumn",
      title: {
        text: this.$t("Quantities")
      },
      xAxis: {

        type: "category",
        categories: optionsData.DATASOURCE.map(x => x.Name),
      },
      yAxis: {
        title: { text: this.$t("Quantity") }
      },
      series: [
        {
          name: this.$t("Total Quantity"),
          color: chartColorPalettes.blue,
          data: optionsData.DATASOURCE.map(x => x.TotalQuantity),
        },
        {
          name: this.$t("NOK Quantity"),
          color: chartColorPalettes.red,
          data: optionsData.DATASOURCE.map(x => x.NOKQuantity),
        },
      ],
    };
    return options;
  })
  let allWorkcentersPPMChart = computed(function () {
    let optionsData = store.getters["pamis-home/getGlobalChartData"];
    let options = {
      widgetType: "HighchartColumn",
      title: {
        text: "PPM",
      },
      xAxis: {
        type: "category",
        categories: optionsData.DATASOURCE.map(x => x.Name),
      },
      yAxis: {
        title: { text: this.$t("Million") }
      },
      series: [
        {
          color: chartColorPalettes.purple,
          name: "PPM",
          data: optionsData.DATASOURCE.map(x => x.PPM),
        },
      ],
    };
    return options;
  })
  let finalKPIChart = computed(function () {
    let optionsData = store.getters["pamis-home/getGlobalChartData"];

    let options = {
      widgetType: "HighchartColumn",
      title: {
        text: this.$t("Production KPI"),
      },
      yAxis: {
        title: { text: this.$t("Percentage") }
      },
      xAxis: {
        type: "category",
        categories: optionsData.DATASOURCE.map(x => x.Name),
      },
      series: [
        {
          name: "OEE",
          color: chartColorPalettes.green,
          data: optionsData.DATASOURCE.map(x => x.OEE),
        },
        {
          name: this.$t("Availability"),
          color: chartColorPalettes.orange,
          data: optionsData.DATASOURCE.map(x => x.Availability),
        },
        {
          name: this.$t("Performance"),
          color: chartColorPalettes.turquoise,
          data: optionsData.DATASOURCE.map(x => x.Efficiency),
        },
        {
          name: this.$t("Quality"),
          color: chartColorPalettes.yellow,
          data: optionsData.DATASOURCE.map(x => x.Quality),
        },
      ],
    };
    return options;
  })
  let breakdownRateChart = computed(function () {
    let optionsData = store.getters["pamis-home/getGlobalChartData"];
    let options = {
      widgetType: "HighchartColumn",
      title: {
        text: this.$t("Breakdown Rate"),
      },
      yAxis: {
        title: {
          text: this.$t("Percentage"),
          margin: 20
        }
      },
      xAxis: {
        type: "category",
        categories: optionsData.DATASOURCE.map(x => x.Name),
      },
      series: [
        {
          color: chartColorPalettes.orange,
          name: this.$t("Breakdown Rate"),
          data: optionsData.DATASOURCE.map(x => x.BreakdownRate),
        },
      ],
    };
    return options;
  })
  return {
    finalQuantityChart,
    allWorkcentersPPMChart,
    finalKPIChart,
    breakdownRateChart
  }
}