<template>
  <div>
    <b-row>
      <b-col>
        <b-form>
          <b-row>
            <b-col cols="2">
              <b-form-select
                @change="onDateSelectChange"
                style="margin-top: 10px"
                v-model="selectedType"
                :options="selectOptions"
                id="home-date-select"
              >
              </b-form-select>
            </b-col>
            <b-col cols="4">
              <b-button
                @click="isAdvanced = !isAdvanced"
                style="
                  margin-top: 10px;
                  background-color: #7fb8ef !important;
                  border-color: #7fb8ef !important;
                "
              >
                <feather-icon icon="CalendarIcon" size="14" />
              </b-button>
            </b-col>
            <b-col cols="2">
              <transition name="slide-fade">
                <b-form-group
                  v-if="isAdvanced"
                  style="margin-top: -10px; margin-bottom: 0px"
                  label-for="example-datepicker"
                  label="Start Date"
                >
                  <flat-pickr
                    v-model="startDate"
                    id="startDate"
                    class="form-control"
                    :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                      minuteIncrement: 1,
                    }"
                  />
                </b-form-group>
              </transition>
            </b-col>
            <b-col cols="2">
              <transition name="slide-fade">
                <b-form-group
                  v-if="isAdvanced"
                  style="margin-top: -10px; margin-bottom: 0px"
                  label-for="example-datepicker"
                  label="End Date"
                >
                  <flat-pickr
                    v-model="endDate"
                    id="endDate"
                    class="form-control"
                    :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                      minuteIncrement: 1,
                      minDate: 'today',
                    }"
                  ></flat-pickr>
                </b-form-group>
              </transition>
            </b-col>
            <transition name="slide-fade">
              <b-col cols="2" v-if="isAdvanced">
                <b-button
                  variant="success"
                  style="margin-top: 11px"
                  @click="searchDataInRange"
                >
                  Search
                </b-button>
              </b-col>
            </transition>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
    <b-row style="margin-top: 25px">
      <b-col style="padding: 0px">
        <b-table class="table-borderless" :items="countryList">
          <template #cell(StartDate)="data">
            <div class="text-nowrap">
              <b-badge pill variant="light-secondary" class="text-capitalize">
                {{ getStartDate }}
              </b-badge>
            </div>
          </template>
          <template #cell(EndDate)="data">
            <div class="text-nowrap">
              <b-badge pill variant="light-secondary" class="text-capitalize">
                {{ getEndDate }}
              </b-badge>
            </div>
          </template>
        </b-table></b-col
      >
    </b-row>
  </div>
</template>

<script>
//emit functions will be implemented.
import flatPickr from "vue-flatpickr-component";
import {
  BButton,
  BCardBody,
  BCardFooter,
  BCardHeader,
  BCard,
  BCardTitle,
  BRow,
  BCol,
  BForm,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BDropdown,
  BDropdownItem,
  BTable,
  BBadge,
} from "bootstrap-vue";
export default {
  name: "DateFilter",
  components: {
    BButton,
    BCardBody,
    BCardFooter,
    BCardHeader,
    BCard,
    BCardTitle,
    BCol,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    flatPickr,
    BTable,
    BBadge,
  },
  data() {
    return {
      isAdvanced: false,
      selectedType: 1,
      selectOptions: [
        { text: "Daily", value: 1 },
        { text: "Weekly", value: 2 },
        { text: "Monthly", value: 3 },
        { text: "Yearly", value: 4 },
        { text: "Yesterday", value: 5 },
        { text: "Last Week", value: 6 },
        { text: "Last Month", value: 7 },
        { text: "Last Year", value: 8 },
      ],
      startDate: new Date(),
      endDate: new Date(),
      countryList: [{ countryName: "Turkey", StartDate: null, EndDate: null }],
    };
  },
  computed: {
    getStartDate() {
      //view format for back-end
      let d = new Date(this.startDate);
      return new Intl.DateTimeFormat("default", {
        day: "2-digit",
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }).format(d);
    },
    getEndDate() {
      let d = new Date(this.endDate);
      return new Intl.DateTimeFormat("default", {
        day: "2-digit",
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }).format(d);
    },
  },
  beforeMount() {
    for (let i in this.countryList) {
      this.countryList[i].StartDate = this.getStartDate;
      this.countryList[i].EndDate = this.getEndDate;
    }
  },
  methods: {
    searchDataInRange() {
      for (let i in this.countryList) {
        this.countryList[i].StartDate = this.getStartDate;
        this.countryList[i].EndDate = this.getEndDate;
      }
    },
    onDateSelectChange(param) {
      let currentDate = new Date();
      if (param == 5) {
        this.startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() - 1,
          0,
          0
        );
        this.endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() - 1,
          23,
          59
        );
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
