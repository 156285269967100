import home_page_chart_data from "./dummy-db/homepagechartsdata.json";
import home_page_table_data from "./dummy-db/homepagetabledata.json";

export default {
  namespaced: true,
  state: {
    globalChartData: null,
    globalTableData: null,
  },
  getters: {
    getGlobalChartData(state) {
      return state.globalChartData;
    },
    getGlobalTableData(state){
      return state.globalTableData;
    }
  },
  actions: {
    getGlobalChartData(ctx) {
      return new Promise((resolve, reject) => {
        ctx.commit("setGlobalChartData", home_page_chart_data);

        resolve(home_page_chart_data);
      });
    },
    getGlobalTableData(ctx) {
      return new Promise((resolve, reject) => {
        ctx.commit("setGlobalTableData", home_page_table_data);
        resolve(home_page_table_data);
      });
    },
  },
  mutations: {
    setGlobalChartData(state, data) {
      state.globalChartData = data;
    },
    setGlobalTableData(state, data) {
      state.globalTableData = data;
    },
  },

 
};
