<template>
  <div>
    <b-card class="outer-card">
      <b-card-title>{{ $t('Niso Global') }}</b-card-title>
      <Widget
        :widgetData="{ widgetType: 'LeafletMap', countryList: countries }"
        class="global-map"
      />
      <Widget
        :widgetData="{ widgetType: 'LegendColorMark' }"
        class="map-widget-card"
      />
    </b-card>
  </div>
</template>
<script>
import useGlobalMapData from "../composables/useGlobalMapData";
import Widget from "../widgets/Widget.vue";
import {
  BCardBody,
  BCardFooter,
  BCardHeader,
  BCard,
  BCardTitle,
  BRow,
  BCol,
} from "bootstrap-vue";
export default {
  name: "GlobalMap",
  components: {
    BCardBody,
    BCardFooter,
    BCardHeader,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    Widget,
  },
  setup() {
    var { countries } = useGlobalMapData();
    return { countries };
  },
};
</script>
<style scoped>
.outer-card {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px !important;
}
.global-map {
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px;
}
</style>
