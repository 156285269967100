<template>
  <div>
    <b-row v-if="false">
      <b-col>
        <div class="editor-bar"> <feather-icon 
            icon="MoveIcon"
            style="color:rgba(170, 170, 170, 0.5);margin:5px;margin-right: 10px;float:right"
            width="1.0vw"
            height="1.0vw"
          /></div>
      </b-col>
    </b-row>
    <b-row>
      <b-col><component  :is="groupName" /> </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol } from "bootstrap-vue";
import { ref,inject } from "@vue/composition-api";
import GroupGeneralCharts from "./GroupGeneralCharts.vue"
import GroupGlobalMap from "./GroupGlobalMap.vue";

export default {
  props: {
    groupName: null,
  },
  components: {
    GroupGeneralCharts,
    GroupGlobalMap,
    BRow,
    BCol,
  },
  setup(props) {
   return {
    
   }
  },
};
//there will be multiple instances of normal bars
</script>
<style scoped>
.editor-bar{
    bottom: 5px;
    height: 100%;
    width: 100%;
    background-color: rgba(240, 240, 240, 0.1);

}
</style>
