import useJwt from "@/auth/jwt/useJwt";
import ApiService from "@/services/pamisDashboardApi.service";

export default {
  namespaced: true,
  state: {
    countries:[]
  },
  getters: {

  },
  actions: {

  },
  mutations: {

  }

 
};
