<template>
  <div>
    <b-row v-if="false" >
      <b-col>
        <div class="editor-bar"> <feather-icon 
            icon="MoveIcon"
            style="color:rgba(170, 170, 170, 0.5);margin:5px;margin-right: 10px;float:right"
            width="15px"
            height="15px"
          /></div>
      </b-col>
    </b-row>
    <b-row>
      <b-col><component  :is="widgetData.widgetType" :data="widgetData"/> </b-col>
    </b-row>
  </div>
</template>
<script>
import LeafletMap from "./map-widgets/LeafletMap.vue";
import LegendColorMark from "./map-widgets/LegendColorMark.vue";
import HighchartColumn from "./chart-widgets/HighchartColumn.vue";

import { BRow, BCol } from "bootstrap-vue";
import { ref,inject } from "@vue/composition-api";
export default {
  props: {
    widgetData: null,
  },
  components: {
    LeafletMap,
    LegendColorMark,
    HighchartColumn,
    BRow,
    BCol,
  },
  setup(props) {
   //const isEditing = inject('isEditing');
   return {
   
   }
  },
};
//there will be multiple instances of normal bars
</script>
<style scoped>
.editor-bar{
    bottom: 5px;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 1.0);
    border: 1px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.0px 2s.6px !important;
}
</style>
