import { computed, ref } from "@vue/composition-api";
import store from "@/store";
export default function useSectionHomeTable() {
  const countries = computed(() => {
    return store.getters["pamis/GetCountries"];
  });

  return {
    countries,
  };
}
